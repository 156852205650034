<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton :color="filters ? 'dark' : 'warning'" size="sm" @click="toggleFilters" style="height: 26px; padding: 2px 5px;"><CIcon name="cil-filter"/></CButton>
          <CButton color="info" size="sm" @click="storeModal()">Nueva Marca</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow v-if="filters">
        <CCol md="3"></CCol>
        <CCol md="3"></CCol>
        <CCol md="3"></CCol>
        <CCol md="3">
          <div role="group" class="form-group" style="margin: -10px 0px 10px">
            <label> Proveedor:</label>
            <CSelect
              :value.sync="providerSelected" 
              :options="providersOptions"
              @change="changeFilters"
            />                        
          </div>
        </CCol>
      </CRow>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #provider_name="{item}"><td class="text-center w200">{{item.provider_name}}</td></template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <BrandModal ref="brandModal" @store="storeProcess" @update="updateProcess"></BrandModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import BrandModal from './BrandModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/brands';
import providers_ws from '../../services/providers';
import store from '../../store'

export default {
  name: 'BrandsTable',
  components: { BrandModal, DeleteModal },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'name', label: 'Nombre'},
          {key: 'provider_name', label: 'Proveedor'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      filters: false,
      toasts: [],
      providers: [],
      providersOptions: [],
      providersSelected: "",
    }
  },
  mounted: async function() {
    let response = await providers_ws.get(); 

    if(response.type == "success"){
      this.providers = response.data;

      this.providersOptions = await this.$parseSelectOptionsOrdered(this.providers, "id", "name", [{value: '0', label: 'Todos'}]);
    }
  },
  methods: {
    toggleFilters () {
      this.filters = !this.filters;
    },
    async changeFilters () {
      this.loading();

      let filters = this.getFilters();
      let response = await ws.getFiltered(filters);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.loaded();
    },
    getFilters(){
      let filters = {
        provider : this.providerSelected
      };

      return filters;
    },
    storeModal () {
      this.$refs.brandModal.storeModal();
    },
    updateModal (item) {
      this.$refs.brandModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Marca", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
